<template>
  <div class="main-page">
    <div class="filter">
        <div class="filter-row" v-for="(item, index) in filterKey" :key="index">
            <div class="filter-label">{{ item }}</div>
            <!-- <div class="filter-content">{{ filter[item] }}</div> -->
            <div class="clickbox-list">
              <div class="clickbox-btn-all" :class="{'all': true, 'is-all': all[item]}" @click="selectAll(item)">All</div>
              <el-checkbox-group v-model="searchValue" @change="valueChange(item, searchValue)" class="t-checkbox-group">
                <el-checkbox v-for="item in filter[item]" :key="item.id" :label="item.id">{{ item.type }}</el-checkbox>
              </el-checkbox-group>
            </div>
        </div>
        <div class="line"></div>
        <div class="filter-search">
          <div class="count">Results Found <span>{{ count }}</span></div>
          <div class="search-bar">
            <input type="text" v-model="title" placeholder="Enter Keyword"/>
            <div class="search-btn" ><i class="el-icon-search"></i></div>
          </div>
        </div>
    </div>

    <div class="solution-content">
        <div class="solution-item" v-for="(item, index) in cases" :key="index">
            <div class="item-cover">
                <img :src="item.coverUrl" :alt="item.title" @click="toPage(item.id)">
            </div>
            <div class="item-title">{{ item.title }}</div>
            <div class="item-brief"><p :title="item.description">{{ item.description }}</p></div>
            <div class="item-create-date">{{ item.createTime | dateFilter}}</div>
        </div>
    </div>
  </div>
</template>

<script>
import {getFilter, getCaseByFilter} from "@/api/caseApi"
export default {
name: '',
data() {
    return {
        count: 0,
        filter: {},
        filterKey: [],
        cases: [],
        title: '',
        searchValue: [],
        // isAll: false,
        all: {}
    }
},
mounted() {
    this.getFilter()
    this.getData()
},
watch: {
    searchValue(n, o) {
        this.getData()
    }
},
methods: {
    toPage(id) {
        console.log(id)
        this.$router.push({
            path: `/case/${id}/detail`
        })
    },
    async getData() {
        const {data: {data, isSuccess}} =  await getCaseByFilter({
            filterIds: this.searchValue
        })
        if (isSuccess) {
            // console.log(data)
            this.count = data.length;
            this.cases = data
        }
    },
    selectAll(category) {
        if (!this.all[category]) {
            this.all[category] = true;
            let values = this.filter[category].map(item => {
                return item.id
            })
            this.searchValue = [...new Set([...this.searchValue, ...values])];
        } else {
            this.all[category] = false;
            let values = this.filter[category].map(item => {
                return item.id
            })
            for (let item of values) {
                this.searchValue.splice(this.searchValue.indexOf(item), 1)
            }
        }
    },
    valueChange(category, value) {
        // let  count = 0;
        // let values = this.filter[category].map(item => item.id)
        // for (let item of value) {
        //     if (values.includes(item)) count++;
        //     if (values.length === count) {
        //         this.all[category] = true;
        //     } else {
        //         this.all[category] = false;
        //     }
        // }
    },
    async getFilter() {
        const {data: {data, isSuccess}} = await getFilter();
        if (isSuccess) {
            for (let item of data) {
                this.all[item.category] = false;
                (this.filter[item.category] || (this.filter[item.category] = [])).push(item);
                this.filterKey = Object.keys(this.filter);
            }
        }
    }
},
filters: {
  dateFilter(value) {
    if (value) {
      return value.split(' ')[0]
    }
    return value;
  }
}
}
</script>

<style lang="less" scoped>
.line {
    margin-top: 10px;
    border-bottom: 1px solid rgb(220, 220, 220);
}
.main-page {
    background-color: #f7f7f7;
    min-height: inherit;
    padding-top: 10px;
}
.filter {
    width: 1080px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    .filter-row {
        display: flex;
        // &:last-child {
        //     border-bottom: 1px solid rgb(220, 220, 220);
        // }
    }
    .filter-row + .filter-row {
        margin-top: 10px;
    }
    .filter-label {
        font-weight: bold;
        width: 80px;
        flex-shrink: 0;
    }
    .clickbox-list {
        margin-left: 10px;
        // display: inline-block;
        display: flex;
        vertical-align: top;
        .clickbox-btn-all {
            display: inline-block;
            margin-right: 30px;
            border: 1px solid transparent;
            padding: 0 11px;
            cursor: pointer;
            border-radius: 3px;
            color: #606266;
            height: 24px;
            line-height: 24px;
        }
        .all {
            border: 1px solid rgb(199, 0, 11);
            color: rgb(199, 0, 11);
        }
        .is-all {
            background-color: rgb(199, 0, 11);
            color: #ffffff;
        }
        .t-checkbox-group {
            display: inline-block;
        }
        /deep/.el-radio {
            // width: 130px;
            height: 24px;
            line-height: 24px;
        }
        /deep/.el-checkbox {
            // width: 130px;
            height: 24px;
            line-height: 24px;
        }
    }
    .filter-search {
        height: 30px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .count {
            margin-right: 30px;
            span {
                color: rgba(199,0,11,0.76);
            }
        }
        .search-bar {
            display: flex;
            height: 30px;
            input {
                outline: 0;
                width: 204px;
                height: 30px;
                padding: 2px 10px;
                border: 1px solid #CCCCCC;
                border-radius: 2px 0 0 2px;
                border-right: none;
            }
            .search-btn {
                height: 30px;
                width: 30px;
                text-align: center;
                line-height: 30px;
                background-color: rgba(199,0,11,0.76);
                cursor: pointer;
                i {
                    height: 30px;
                    width: 30px;
                    line-height: 30px;
                    color: #ffffff;
                    font-size: 18px;
                }
            }
        }
    }
}
.solution-content {
    // margin-top: 20px;
    width: 1080px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 20px;
    .solution-item {
        background-color: #ffffff;
        width: 346.6px;
        padding: 10px;
        margin-bottom: 20px;
        cursor: pointer;
        &:not(:nth-child(3n)) {
            margin-right: 20px;
        }
        .item-cover {
            width: 100%;
            height: 160px;
            margin-bottom: 10px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .item-title {
            font-size: 16px;
            font-weight: bold;
        }
        .item-brief {
            color: #666666;
            margin-top: 10px;
        p {
            font-size: 13px;
            display: -webkit-box;
            height: 40px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
        }
        }
        .item-create-date {
            color: #666666;
            font-size: 12px;
            margin-top: 10px;
        }
    }
}
</style>