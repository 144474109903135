import axiosApi from '@/api/axiosApi';

export const getFilter = (data) => axiosApi({
    method: 'POST',
    url: '/intserv/case/getFilter',
    data,
});

export const getCaseByFilter = (data) => axiosApi({
    method: 'POST',
    url: '/intserv/case/getCaseByFilter',
    data,
});

export const getCaseContentByCaseId = (data) => axiosApi({
    method: 'POST',
    url: '/intserv/case/getCaseContentByCaseId',
    data,
});

export const getCaseDocByCaseId = (data) => axiosApi({
    method: 'POST',
    url: '/intserv/case/getCaseDocByCaseId',
    data
})
